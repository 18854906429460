<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="点击搜索证书"
            @click="search"
            readonly
            ref="keyword"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../../assets/images/educate/ban01.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid :column-num="4">
        <van-grid-item text="幼儿园" key="1" @click="classify('KGS')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-01"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="小学" key="1" @click="classify('PMS')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-02"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="初中" key="2" @click="classify('JNS')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-03"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="高中" key="3" @click="classify('HGS')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-04"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="完全中学" key="1" @click="classify('S06')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-05"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="九年一贯制" key="2" @click="classify('S09')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu-06"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="十二年一贯制" key="3" @click="classify('S12')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-course"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="职业高中" key="1" @click="classify('VHS')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-ero"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 热门学校</van-col
        >
        <van-col span="12" class="btn color-m"
          ><div @click="search">全部学校 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <Sift :callback="siftCondition" ref="sift" />
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveDisplaySchoolList"
      >
        <template #default>
          <div
            class="school"
            v-for="school in schoolList"
            :key="school.code"
            @click="detail(school.code)"
          >
            <div class="name text-short">
              {{ school.name }}
            </div>
            <van-row>
              <van-col span="12" class="type"
                >学校类型：<van-tag
                  :color="COLOR_M"
                  v-if="school.type === 'PMS'"
                  >小学</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'JNS'"
                  >初中</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'HGS'"
                  >高中</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S06'"
                  >完全中学</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S09'"
                  >九年一贯制</van-tag
                ><van-tag :color="COLOR_M" v-if="school.type === 'S12'"
                  >十二年一贯制</van-tag
                ></van-col
              >
              <van-col span="12" class="region"
                >所在城市：<van-tag :color="COLOR_M"
                  >西安市-雁塔区</van-tag
                ></van-col
              >
            </van-row>
            <div class="address text-short">学校地址：{{ school.address }}</div>
          </div>
          <van-row v-if="schoolList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="EDUCATE_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="EDU" module-label="EDU_HOME_INDEX" />
    <Clue ref="clue" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
