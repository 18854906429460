<template>
  <div>
    <van-dropdown-menu class="dropdown">
      <!-- <van-dropdown-item v-model="type" :options="types" />
      <van-dropdown-item v-model="sort" :options="sorts" /> -->
      <van-dropdown-item :title="condition.regionTitle" ref="region">
        <van-area
          :value="region"
          :area-list="regions"
          columns-num="3"
          item-height="30"
          @cancel="cancelRegion"
          @confirm="confirmRegion"
        />
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import { DropdownMenu, DropdownItem, Popup, Area } from 'vant'
export default {
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [Area.name]: Area
  },
  data () {
    return {
      condition: { regionTitle: '请选择城市' },
      regionPopupShow: false,
      classify: {},
      regions: {},
      region: '610100'
    }
  },
  props: {
    callback: Function
  },
  mounted () {
    this.initConditionCache()
    this.retrieveRegions()
  },
  methods: {
    confirmRegion (val) {
      var code = val[2].code
      this.condition.region = code
      this.region = code
      this.condition.regionTitle = val[1].name + '-' + val[2].name
      this.cacheCondition()
      this.callback(this.condition)
      this.$refs.region.toggle()
    },
    cancelRegion () {
      this.condition.region = ''
      this.condition.regionTitle = '请选择城市'
      this.cacheCondition()
      this.callback(this.condition)
      this.$refs.region.toggle()
    },
    async retrieveRegions () {
      var pd = { level: 3 }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regions.province_list = res.data.one
        this.regions.city_list = res.data.two
        this.regions.county_list = res.data.three
      }
    },
    initCondition (condition) {
      this.classify = condition.classify
    },
    cacheCondition () {
      window.sessionStorage.setItem(window.const.global.EDUCATE_SEARCH_SIFT, JSON.stringify(this.condition))
    },
    initConditionCache () {
      var condition = window.sessionStorage.getItem(window.const.global.RECRUIT_SEARCH_SIFT)
      if (condition !== undefined && condition != null && condition !== '') {
        var condObj = JSON.parse(condition)
        this.condition = condObj
        this.callback(this.condition)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .dropdown {
  .van-dropdown-menu__bar {
    position: relative;
    display: flex;
    height: 35px;
    background-color: #fff;
    border-bottom: 1px solid #ebedf0;
    box-shadow: 0 0px 0px rgba(100, 101, 102, 0.12);
  }
  .van-dropdown-menu__title {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 8px;
    color: #323233;
    font-size: 14px;
    line-height: 22px;
  }
  .van-collapse-item__content {
    padding: 8px 16px;
    color: #969799;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 5px 0px 5px 20px;
    border-bottom: 1px solid #eee;
  }

  .collapse {
    text-align: left;
  }
  .button {
    margin: 10px;
  }
}
</style>
