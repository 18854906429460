import { Search, Icon, Swipe, SwipeItem, Tag, Grid, GridItem, Image } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Sift from '../common/Sift.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Sift: Sift,
        Call: Call,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Image.name]: Image
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifyCode: '',
            regionCode: '',
            page: { current: 0, size: 20, last: 0 },
            schoolList: []
        }
    },
    mounted () {
        this.retrieveDisplaySchoolPage()
        this.$refs.share.default('main')
        this.createVistClue()
    },
    methods: {
        search () {
            window.sessionStorage.setItem(window.const.global.EDUCATE_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/educate/search' })
        },
        siftSearch (condition) {
            this.classifyCode = condition.classifyCode
            this.search()
        },
        classify (type) {
            window.sessionStorage.setItem(window.const.global.EDUCATE_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/educate/search', query: { type: type } })
        },
        async retrieveDisplaySchoolPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { label: 'INDEX_HOT', regionCode: this.regionCode, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/educate/schoolAPC/retrieveDisplaySchoolPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.schoolList = this.schoolList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        initCondition () {
            var condition = { regionText: '' }
            this.$refs.sift.initCondition(condition)
        },
        siftCondition (condition) {
            this.schoolList = []
            this.finished = false
            this.page.current = 0
            this.regionCode = condition.region
            this.retrieveDisplaySchoolPage()
        },
        detail (code) {
            var key = window.const.global.EDUCATE_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/educate/detail', query: { code: code } })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = 'K12入学访问'
            this.$refs.clue.createClue('EDUCATE_VIST_HOME', '0', JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.EDUCATE_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
